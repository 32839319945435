import React, { FC, useState } from 'react';

import { UserType } from '@/apolloGenerated';
import { Container } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
} from '@entities/Contact';
import { ContactTable, ContactTableHeader } from '@features/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import { Divider, Spacer } from '@letsdance/ui-kit';

export const ContactLists: FC = () => {
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const { data, error, loading, onUpdatePayload, payload, refetch } =
    useUsersList({
      tab,
    });

  return (
    <>
      <Container>
        <ContactListTabs onChange={setTab} />
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <ContactTable
          hasError={!!error}
          tab={tab}
          data={(data?.rows as UserType[]) || []}
          headers={[
            ContactTableHeader.Icon,
            ContactTableHeader.CreatedAt,
            ContactTableHeader.Name,
            ContactTableHeader.Data,
            ContactTableHeader.Orders,
            ContactTableHeader.Visited,
            ContactTableHeader.Receipts,
            ContactTableHeader.BonusAmount,
            ContactTableHeader.Settings,
          ]}
          loading={loading}
          total={data?.count || 0}
          payload={payload}
          clickedRow
          onChangePayload={onUpdatePayload}
          onRefetch={error && refetch}
        />
      </Container>
    </>
  );
};
