import React, { FC, useContext, useMemo, useState } from 'react';

import {
  StatusEnum,
  TariffsListQuery,
  usePurchaseStatsQuery,
  useTariffsListQuery,
} from '@/apolloGenerated';
import {
  FloatDriver,
  renderCellDate,
  renderCellItemValue,
  StatItem,
  StatWrap,
  useOrganizerId,
} from '@/shared';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { getPaidOrdersCount } from '@entities/Purchases/libs/utils';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import {
  Button,
  Divider,
  isNumber,
  Spacer,
  TableController,
} from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { Head } from '@widgets/Layout';

import styles from './styles.module.scss';

const getStat = (stat?: number) => stat || 0;

export const PurchasesStats: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const organizerId = useOrganizerId()!;
  const [isOpenTariffInfo, setIsOpenTariffInfo] = useState<boolean>(false);
  const { data } = usePurchaseStatsQuery({
    skip: !organizerId,
    variables: { organizerId, uuidProduct: productUuid },
  });
  const {
    data: dataTariff,
    error: errorTariffs,
    loading: loadingTariffs,
    refetch: refetchTariffs,
  } = useTariffsListQuery({
    skip: !productUuid,
    variables: {
      productUuid: productUuid!,
      status: [StatusEnum.Active, StatusEnum.Archive, StatusEnum.Draft],
    },
  });

  const stats = data?.purchasesStats;
  const usersCounts = stats?.usersCounts;
  const rationUsers =
    usersCounts?.female && usersCounts?.male
      ? (usersCounts.female / usersCounts.male).toFixed(2)
      : 0;

  const onCloseTariffInfo = () => {
    setIsOpenTariffInfo(false);
  };

  const rowTemplateTariffs = ({
    availableOrdersCount,
    endDate,
    name,
    ordersCount,
    paidOrdersCount,
    startedDate,
    status,
    type,
    uuid,
  }: TariffsListQuery['tariffsList']['rows'][0]) => ({
    availableOrders:
      isNumber(ordersCount) && isNumber(availableOrdersCount)
        ? `Доступно ${availableOrdersCount} из ${ordersCount}`
        : '-',
    endDate: endDate ? renderCellDate({ time: true, timestamp: endDate }) : '-',
    info: renderCellItemValue({
      label: name,
      value: type ? TariffTypeTranslate[type] : '-',
    }),
    orders: String(getPaidOrdersCount(paidOrdersCount, type)),
    startDate: startedDate
      ? renderCellDate({
          time: true,
          timestamp: startedDate,
        })
      : '-',
    status: readableStatus(status),
    uuid: uuid,
  });
  const itemsTariffs = useMemo(
    () => (dataTariff?.tariffsList.rows || []).map(rowTemplateTariffs),
    [dataTariff],
  );

  return (
    <div className={styles.purchaseStats}>
      <div className={styles.purchaseStats__row}>
        <StatWrap>
          <StatItem
            label="Всего получено, ₽"
            value={getStat(stats?.profit)}
            format
            fixed={false}
          />
        </StatWrap>
        <StatWrap>
          <StatItem label="Пользователи" value={getStat(usersCounts?.all)} />
          <Divider vertical />
          <StatItem label="Мужчины" value={getStat(usersCounts?.male)} />
          <Divider vertical />
          <StatItem label="Женщины" value={getStat(usersCounts?.female)} />
          <Divider vertical />
          <StatItem label="Ж / М" value={rationUsers} />
        </StatWrap>
        {productUuid && (
          <Button
            color="secondary"
            loading={loadingTariffs}
            style={{ minWidth: 190 }}
            onClick={() => setIsOpenTariffInfo(true)}>
            Информация по тарифам
          </Button>
        )}
      </div>
      <FloatDriver isOpen={isOpenTariffInfo} onClose={onCloseTariffInfo}>
        <Head title="Информация по тарифам" onBack={onCloseTariffInfo} />
        {!!dataTariff?.tariffsList.rows.length && (
          <TableController
            hasError={!!errorTariffs}
            onRefetch={errorTariffs && refetchTariffs}
            data={itemsTariffs}
            headers={[
              { hide: true, key: true, value: 'uuid' },
              { title: 'Данные', value: 'info' },
              { title: 'Статус ', value: 'status' },
              { title: 'Дата активации', value: 'startDate' },
              { title: 'Дата деактивации', value: 'endDate' },
              { title: 'Доступно', value: 'availableOrders' },
              { title: 'Куплено', value: 'orders' },
            ]}
          />
        )}
        <Spacer size={10} />
      </FloatDriver>
    </div>
  );
};
