import { FC, useState } from 'react';
import moment from 'moment-timezone';

import {
  ProductInput,
  StatusEnum,
  TariffType,
  TariffTypeEnum,
} from '@/apolloGenerated';
import { FloatDriver, ListPicker, timezone } from '@/shared';
import { getPaidOrdersCount } from '@entities/Purchases/libs/utils';
import { Maybe } from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { TariffCreate, TariffEdit } from '@widgets/Tariff';

export interface TariffPickerItem {
  uuid: string;
  name: string;
  status: StatusEnum;
  type: TariffTypeEnum;
  startedDate?: Maybe<string>;
  endDate?: Maybe<string>;
  availableOrdersCount?: Maybe<number>;
  ordersCount?: Maybe<number>;
  paidOrdersCount?: number;
}
export interface EventTariffsPickerProps {
  eventData: ProductInput;
  initValue?: TariffPickerItem[];
  disabled?: boolean;
  onChange(val: string[]): void;
}
interface TariffEditor {
  readonly?: boolean;
  type: 'edit' | 'create';
  uuid?: string;
}
export const EventTariffsPicker: FC<EventTariffsPickerProps> = ({
  disabled,
  eventData,
  initValue,
  onChange,
}) => {
  const [editor, setEditor] = useState<Maybe<TariffEditor>>(null);

  const [value, setValue] = useState<TariffPickerItem[]>(initValue || []);

  const handleClose = () => {
    setEditor(null);
  };

  const handleChange = (val: TariffPickerItem[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
    handleClose();
  };

  const handleAdd = (data: TariffType) => {
    handleChange([...value, data]);
    handleClose();
  };

  const handleEdit = (uuid: string, data: TariffType) => {
    const newValues = [...value];
    const elIndex = newValues.findIndex((el) => el.uuid === uuid);

    if (elIndex !== -1) {
      newValues[elIndex] = data;
      handleChange(newValues);
    }
  };

  const renderSubtitle = ({
    availableOrdersCount,
    endDate,
    ordersCount,
    paidOrdersCount,
    startedDate,
    status,
    type,
  }: TariffPickerItem) => {
    let text = readableStatus(status);

    if (status === StatusEnum.Draft && startedDate) {
      text += ` до ${moment(startedDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (status === StatusEnum.Active && endDate) {
      text += ` до ${moment(endDate).tz(timezone).format('DD MMM YYYY HH:mm')}`;
    }

    if (ordersCount) {
      text += ` (доступно ${availableOrdersCount} из ${ordersCount})`;
    }

    if (paidOrdersCount) {
      text += ` (куплено ${getPaidOrdersCount(paidOrdersCount, type)})`;
    }

    return text;
  };

  return (
    <>
      <ListPicker
        initValues={value}
        labelRender={(item) => item.name}
        valueKey="uuid"
        renderSubtitle={renderSubtitle}
        onChange={handleChange}
        onAdd={() =>
          setEditor({
            type: 'create',
          })
        }
        onEdit={(val, readonly) =>
          setEditor({ readonly, type: 'edit', uuid: val.uuid })
        }
        disabled={disabled}
        texts={{
          addBtn: 'Добавить тариф',
          removeModal: {
            description:
              'После удаления необходимо сохранить событие, чтобы обновить тарифы',
            title: 'Удалить тариф?',
          },
        }}
      />
      <FloatDriver isOpen={!!editor} onClose={handleClose}>
        {editor?.type === 'edit' && (
          <TariffEdit
            eventData={eventData}
            uuid={editor.uuid!}
            disabled={editor.readonly}
            onClose={handleClose}
            onSuccess={(data) => handleEdit(editor.uuid!, data)}
          />
        )}
        {editor?.type === 'create' && (
          <TariffCreate
            eventData={eventData}
            onClose={handleClose}
            onSuccess={(data) => handleAdd(data)}
          />
        )}
      </FloatDriver>
    </>
  );
};
