import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { ProductInput } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import { RouterName } from '@app/router';
import {
  EventForm,
  EventInputSteps,
  useCreateEvent,
  useEventInitState,
} from '@entities/Event';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const EventCreateView: FC = () => {
  const { getEventInitState, loading: loadingInitState } = useEventInitState();
  const { navigate } = useNavigate();
  const [createEvent, { error, loading }] = useCreateEvent();
  const [eventData, setEventData] = useState<ProductInput>();

  const onClose = () => {
    navigate(RouterName.AdminEvents);
  };
  const onCreate = async (publish: boolean) => {
    if (!eventData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    createEvent({
      input: eventData,
      publish,
    });
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: () => onCreate(false),
      label: 'Сохранить черновик',
    },
    {
      handler: () => onCreate(true),
      label: 'Опубликовать',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading} />
      <Container slim>
        <Head title="Новая вечеринка" onBack={onClose} />
        <EventInputSteps />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {!loadingInitState && (
          <EventForm
            initValues={getEventInitState() as any}
            initTariffs={[]}
            onChange={setEventData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
      </Container>
    </>
  );
};
