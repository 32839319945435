import React, { FC, useEffect, useState } from 'react';

import {
  ProductInput,
  TariffInput,
  TariffType,
  useAllAllowedUsersForTariffQuery,
  UserType,
  useTariffQuery,
} from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService } from '@/shared';
import {
  TariffForm,
  useAccessTariffUsers,
  useUpdateTariff,
} from '@entities/Tariff';
import { ContactTariffAccessSelect } from '@features/Contact';
import { LoaderOverlay, Spacer, toaster, Typography } from '@letsdance/ui-kit';
import { readableStatus } from '@shared/libs/utils/format';
import { Head } from '@widgets/Layout';

export interface TariffEditProps {
  eventData: ProductInput;
  uuid: string;
  disabled?: boolean;
  onClose(): void;
  onSuccess(val: TariffType): void;
}
export const TariffEdit: FC<TariffEditProps> = ({
  disabled,
  eventData,
  onClose,
  onSuccess,
  uuid,
}) => {
  const { data, loading } = useTariffQuery({ variables: { uuid } });
  const [updateTariff, { data: updateData, error, loading: saveLoading }] =
    useUpdateTariff();
  const [updateUserAccessTariff, { loading: saveAccessLoading }] =
    useAccessTariffUsers();
  const [tariffData, setTariffData] = useState<TariffInput>();
  const [usersAccessUuids, setUserAccessUuids] = useState<string[]>([]);
  const { data: usersData } = useAllAllowedUsersForTariffQuery({
    variables: { uuid },
  });

  const handleSubmit = () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для тарифа' });
    }
    updateTariff({
      input: tariffData,
      uuid,
    });
  };

  useEffect(() => {
    if (updateData) {
      onSuccess(updateData.updateTariff);
      updateUserAccessTariff({ userUuids: usersAccessUuids, uuid });
    }
  }, [updateData]);

  return (
    <div>
      <LoaderOverlay show={loading || saveLoading || saveAccessLoading} />
      <Head
        title={data?.adminTariff.name || ''}
        rightSlot={
          data && (
            <Typography variant="body-16" color="on-surface-primary-1">
              {readableStatus(data.adminTariff.status)}
            </Typography>
          )
        }
      />
      {!disabled && (
        <ActionButtons
          actions={[
            { handler: onClose, label: 'Отменить' },
            { handler: handleSubmit, label: 'Сохранить изменения' },
          ]}
        />
      )}

      <Spacer size={10} />
      {data && (
        <>
          <TariffForm
            eventData={eventData}
            initValues={data.adminTariff}
            readonly={disabled}
            onChange={setTariffData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
          {usersData && (
            <ContactTariffAccessSelect
              initValue={
                (usersData.allAllowedUsersForTariff || []) as UserType[]
              }
              onChange={setUserAccessUuids}
            />
          )}
        </>
      )}
    </div>
  );
};
